import React from 'react'
import "swagger-ui-react/swagger-ui.css"
import YAMLData from '../../../openapi.yaml'
import { RedocStandalone } from 'redoc';
import HeaderHead from "../../../components/HeaderHead";
import { Helmet } from "react-helmet";

const Reference = ({ children, location, title = null }) => {
    return (
        <div>
            <Helmet>
                <title>Firstparty - API Reference</title>
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>
            <div className="px-3">
                <HeaderHead fluid={true} />
            </div>
            <section className="pb-12">
                <div className="documentation-container">
                    <RedocStandalone
                        spec={YAMLData}
                        nativeScrollbars={false}
                        scrollYOffset="56px"
                        options={
                            {
                                pathInMiddlePanel: false,
                                disableSearch: true,
                                theme: {
                                    colors: {
                                        primary: {
                                            main: "#27687D"
                                        },
                                        success: {
                                            main: "#27687D"
                                        },
                                        warning: {
                                            main: "#FAD776"
                                        },
                                        error: {
                                            main: "#AD0300"
                                        },
                                        gray: {
                                            50: "#D9E2EF",
                                            100: "#C6D3E6"
                                        },
                                        text: {
                                            primary: "#040a0c"
                                        },
                                        http: {
                                            get: '#0AAD3D',
                                            post: '#106157',
                                            put: '#61105C',
                                            options: '#FAD776',
                                            patch: '#7C69EF',
                                            delete: '#AD0300',
                                            basic: '#506690',
                                            link: '#506690',
                                            head: '#6C8AEC',
                                        }
                                    },
                                    typography: {
                                        // fontFamily: '"HKGroteskPro", serif'
                                    },
                                    sidebar: {
                                        backgroundColor: "#FFFFFF"
                                    },
                                    rightPanel: {
                                        backgroundColor: "#040a0c"
                                    }
                                }
                            }
                        }
                    />
                </div>
            </section>
        </div>
    )
}

export default Reference
